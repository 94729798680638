import { createContext, ReactNode } from "react";
import defaultConfig from "@/config";
import useLocalStorage from "../hooks/useLocalStorage";
import { notification, NotificationArgsProps } from "antd";

type OnLoginType = { token: string; isLoggedIn: boolean; expiresAt: string };
export type NotificationPropsType = { type: NotificationType; options: NotificationArgsProps };
type NotificationType = "success" | "info" | "warning" | "error" | "open";

// initial state
const initialState = {
	...defaultConfig,
	onLogin: (payload: OnLoginType) => {},
	onLogout: () => {},
	onChangeNotification: (arg1?: any) => {},
	updateClientProfile: (arg1?: any) => {},
	resetContext: () => {},
};

const AppContext = createContext(initialState);
type AppConfigProviderProps = {
	children: ReactNode;
};

function AppContextProvider({ children }: AppConfigProviderProps) {
	const [api, contextHolder] = notification.useNotification();
	const [config, setConfig] = useLocalStorage("question-directory-app", initialState);

	const onLogin = (payload: OnLoginType) => {
		setConfig({
			...config,
			...payload,
		});
	};

	const onLogout = () => {
		resetContext();
	};
	const resetContext = () => {
		setConfig({
			...initialState,
		});
	};

	const onChangeNotification = ({ type = "open", options }: NotificationPropsType) => {
		const { message, description, ...restOptions } = options;
		api[type]({
			message: options?.message ?? "",
			description: options?.description ?? <></>,
			...restOptions,
		});
	};

	const updateClientProfile = (userDetails: any) => {
		setConfig((prevState: any) => ({
			...prevState,
			userDetails: { ...userDetails },
		}));
	};

	return (
		<AppContext.Provider
			value={{
				...config,
				onLogin,
				onLogout,
				onChangeNotification,
				updateClientProfile,
				resetContext,
			}}>
			{children}
			{contextHolder}
		</AppContext.Provider>
	);
}

export { AppContextProvider, AppContext };
