export const APP_NAME = process.env.APP_NAME!
export const API_ENDPOINT = process.env.API_ENDPOINT!
export const REPORT_DOWNLOAD_ENDPOINT = process.env.REPORT_DOWNLOAD_ENDPOINT!

export const KEY_HOMEPAGE_URL = "/dashboard"
export const GET_PREV_ROUTE_KEY = "PREV_ROUTE"
export const KEY_LOGIN_URL = "/login"
export const KEY_COOKIES_AUTH_TOKEN: string = "authToken-questionDic"
export const KEY_REDIRECT_PARAM = "redirect_to"
export const KEY_DEVICE_UUID = "oskies-device-uuid"
export const HOMEPAGE_AFTER_AUTH = "/"
export const KEY_TO_GET_PREV_ROUTE = "routeBeforeAuth"
