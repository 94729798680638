import React from "react";
import "./styles/global.css";
import { ConfigProvider } from "antd";
import RouterProvider from "./routes/RouterProvider";
import { AppContextProvider } from "./context/AppContext";
import { SearchContextProvider } from "./context/SearchContext";

function App() {
	return (
		<AppContextProvider>
			<SearchContextProvider>
				<ConfigProvider theme={{ token: { colorPrimary: "#21664D" } }}>
					<RouterProvider />
				</ConfigProvider>
			</SearchContextProvider>
		</AppContextProvider>
	);
}

export default App;
