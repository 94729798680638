import { destroyCookie, parseCookies, setCookie } from "nookies";

const DEFAULT_OPTIONS = {
	maxAge: 365 * 24 * 60 * 60, // 1 year
	path: "/",
};

const Cookies = {
	/**
	 * Sets a cookie.
	 * @param name The name of your cookie.
	 * @param value The value of your cookie.
	 * @param ctx NextJS page or API context, express context, null or undefined.
	 */
	set: (name: string, value: string): void => {
		setCookie(null, name, value, DEFAULT_OPTIONS);
	},

	/**
	 * Get cookie by name/ key
	 * @param name the name of the cookie
	 * @param ctx Next.js context
	 * @returns string | undefined
	 */
	get: (name: string): string | undefined => {
		let cookies: { [index: string]: string } = {};

		cookies = parseCookies(null, DEFAULT_OPTIONS);

		return cookies[name];
	},

	/**
	 * Destroys cookie by their name
	 * @param name Name/key of the cookie
	 * @param ctx Next.js context
	 */
	destroy: (name: string) => {
		destroyCookie(null, name, DEFAULT_OPTIONS);
	},
};

export default Cookies;
