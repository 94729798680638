import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
const HomePage = lazy(() => import("@/pages/HomePage"));
const LoginPage = lazy(() => import("@/pages/LoginPage"));
const DefaultLayout = lazy(() => import("@/Layouts/DefaultLayout"));
const AdminLayout = lazy(() => import("@/Layouts/AdminLayout"));
const QuestionIndex = lazy(() => import("@/pages/question"));
const Add = lazy(() => import("@/pages/question/Add"));
const Update = lazy(() => import("@/pages/question/Update"));

const CategoryIndex = lazy(() => import("@/pages/category"));
const CategoryAdd = lazy(() => import("@/pages/category/Add"));
const CategoryUpdate = lazy(() => import("@/pages/category/Update"));

const TagIndex = lazy(() => import("@/pages/tag"));
const TagAdd = lazy(() => import("@/pages/tag/Add"));
const TagUpdate = lazy(() => import("@/pages/tag/Update"));

const routes = createBrowserRouter([
  {
    path: "/",
    Component: DefaultLayout,
    children: [
      {
        path: "/",
        Component: HomePage,
      },
    ],
  },
  {
    path: "/admin-login",
    children: [
      {
        path: "/admin-login",
        Component: LoginPage,
      },
    ],
  },
  {
    path: "/question-management",
    element: <AdminLayout />,
    children: [
      {
        path: "/question-management",
        element: <ProtectedRoute Comp={QuestionIndex} />,
      },
      {
        path: "/question-management/add",
        element: <ProtectedRoute Comp={Add} />,
      },
      {
        path: "/question-management/update/:uid",
        element: <ProtectedRoute Comp={Update} />,
      },
      {
        path: "/question-management/category",
        element: <ProtectedRoute Comp={CategoryIndex} />,
      },
      {
        path: "/question-management/category/add",
        element: <ProtectedRoute Comp={CategoryAdd} />,
      },
      {
        path: "/question-management/category/update/:uid",
        element: <ProtectedRoute Comp={CategoryUpdate} />,
      },
      {
        path: "/question-management/tag",
        element: <ProtectedRoute Comp={TagIndex} />,
      },
      {
        path: "/question-management/tag/add",
        element: <ProtectedRoute Comp={TagAdd} />,
      },
      {
        path: "/question-management/tag/update/:uid",
        element: <ProtectedRoute Comp={TagUpdate} />,
      },
    ],
  },
]);

export default routes;
