import React from "react";
import useAppContext from "@/hooks/useAppContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, Comp }: any) => {
	const user = useAppContext();
	if (!(user?.isLoggedIn && user?.token)) {
		return <Navigate to='/' replace />;
	}
	return <>{children ?? <Comp />}</>;
};

export default ProtectedRoute;
