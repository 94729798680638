export const APP_DEFAULT_PATH = "/dashboard/";
export const HORIZONTAL_MAX_ITEM = 6;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_DESKTOP = 260;

const userDetails = {
	id: "",
	email: "",
	name: "",
	uid: "",
};

const config = {
	isLoggedIn: false,
	token: "",
	expiresAt: "",
	userDetails: userDetails,
};

export default config;
