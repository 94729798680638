import { KEY_COOKIES_AUTH_TOKEN } from "@/config/variables";
import Cookies from "@/helpers/cookies";
import axios from "axios";

const axoisInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	withCredentials: false,
});

axoisInstance.interceptors.request.use(
	function (config: any) {
		const token = Cookies.get(KEY_COOKIES_AUTH_TOKEN);
		const headers = {
			"Content-Type": "application/json",
			Authorization: Boolean(token) ? `Bareer ${token}` : "",
		};
		return {
			...config,
			headers: {
				...config.headers,
				...headers,
			},
		};
	},
	function (error) {
		console.log("Promise.reject->", error);
		return Promise.reject(error);
	}
);

export default axoisInstance;
