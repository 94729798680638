import axoisInstance from "@/lib/axoisInstance";
import { createContext, ReactNode, useState } from "react";

// initial state
const initialState = {
  filters: [],
  query: "",
  questions: [],
  isError: false,
  errorMessage: "",
  isLoading: false,
  count: null,
  setQuestions: (payload: any) => {},
  onUpdateFilter: (payload: any) => {},
  onUpdateTags: (payload: any) => {},
  updateIsLoading: (payload: boolean) => {},
  setCount: (count: any) => {},
  onError: (payload: { isError: boolean; errorMessage: string }) => {},
  resetContext: () => {},
  getQuestions: (payload: any) => {},
  tags: [],
};

const SearchContext = createContext(initialState);
type SearchProviderProps = {
  children: ReactNode;
};

function SearchContextProvider({ children }: SearchProviderProps) {
  const [state, setState] = useState(initialState);

  const setQuestions = (payload: any) => {
    setState((prev) => ({ ...prev, questions: payload }));
  };
  const setCount = (count: any) => {
    setState((prev) => ({ ...prev, count }));
  };

  const onError = (payload: { isError: boolean; errorMessage: string }) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  const onUpdateFilter = (filters: any) => {
    setState((prev) => ({ ...prev, filters }));
  };
  const onUpdateTags = (tags: any) => {
    setState((prev) => ({ ...prev, tags }));
  };

  const updateIsLoading = (isLoading: boolean) => {
    setState((prev) => ({ ...prev, isLoading }));
  };

  const resetContext = () => {
    setState({
      ...initialState,
    });
  };

  const getQuestions = async (args: { pagination: any; filter: any; searchQuery: string; tags: any }) => {
    const { pagination, filter, searchQuery, tags } = args;
    const { skip, limit } = pagination ?? {};
    try {
      let questions;
      if (skip && limit && !filter && !tags) {
        questions = await axoisInstance.get("question", { params: { skip, limit } });
      } else if (!skip && !limit && searchQuery) {
        questions = await axoisInstance.get("question", { params: { searchQuery } });
      } else if (filter && !skip && !limit) {
        questions = await axoisInstance.get("question", { params: { filter } });
      } else if (tags && !skip && !limit) {
        questions = await axoisInstance.get("question", { params: { tags } });
      } else {
        questions = await axoisInstance.get("question");
      }
      return questions;
    } catch (e: any) {
      return e;
    }
  };

  return (
    <SearchContext.Provider
      value={{
        ...state,
        resetContext,
        setQuestions,
        onUpdateFilter,
        onUpdateTags,
        updateIsLoading,
        onError,
        setCount,
        getQuestions,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContextProvider, SearchContext };
